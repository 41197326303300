<template>
    <div class="defaultV2-signIn">
        <div class="sing-wrap">
            <div class="right-plan">
                <div class="title">
                    <!-- <div class="logo-wrap">
                        <img src="@/assets/singn/defaultV2/logo.png" alt="">
                    </div> -->
                    <div class="name">signInAdmin</div>
                </div>
                <div class="loginForm">
                    <el-form ref="form" :model="loginForm" label-position='top'>
                        <el-form-item label="用户名">
                            <el-input v-model="loginForm.username" placeholder="请输入账号名"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                        </el-form-item>                    
                    </el-form>
                    
                    
                </div>
                <div class="sign-btn" @click="handleLogin">Singn in</div>
            </div>
        </div>

    </div>
</template>

<script>
import { saSetToken } from "../../methods/index";
import { localSave } from "@/methods/util";
export default {
    data() {
        return {
            loginForm:{
                username:'',
                password:'',
            }
        }
    },
    methods: {
        async handleLogin(){
            if(this.loginForm.username === '' || this.loginForm.password === ''){
                this.$message.warning('请完善登录信息');
                return
            }
            let params = {
                username:this.loginForm.username,
                password:this.loginForm.password,
                roleId: 1,
            }
            let res = await this.$API.login(params).catch(err=>{console.log(err);})
            if (res.code === 0) {
                saSetToken(res.data.token);
                this.requestUserInfo(res.data.userId);
                this.$router.push("/adminHome")
                this.$message.success("登录成功");
            } else {
                this.$message.closeAll();
                this.$message.error(res.msg);
            }
        },
        // 保存用户信息
        requestUserInfo(id){
            let params = {
                id: id
            }
            this.$API.getUserInfo(params).then( res => {
                if(res.code === 0){
                    let userInfo = res.data
                    localSave("byUserInfo", userInfo);
                }
            })
        }
    }
}
</script>

<style lang="scss">
.defaultV2-signIn{
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(to right, #69858B , #236470);
    background-color: #F3F7F9;
    display: flex;
    justify-content: center;
    align-items: center;
    .sing-wrap{
        // height: 400px;
        display: flex;
        .right-plan{
            width:450px;
            height: 100%;
            // text-align: center;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 0px 1px #ccc;
            padding: 20px 40px 50px 40px;
            box-sizing: border-box;
            .title{
                // color: #fff;
                font-size: 25px;
                margin-bottom: 20px;
                display: flex;
                .logo-wrap{
                    width: 55px;
                    height: 55px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .name{
                    margin-top: 20px;
                    font-weight: 600;
                }
            }
            .loginForm{
                
                .el-form-item__label{
                    line-height: 30px;
                    padding:0 0 0 0;
                    color: #7D9EB5;
                }
                .el-form-item{
                    margin-bottom: 15px;
                }
                .el-input__inner{
                    border: 1px solid #CCDFEA;
                    height: 40px;
                    line-height: 40px;
                    // color: #fff;
                    // background-color: #fff;
                    border-radius: 5px;
                }
                .el-input__inner:focus{
                    border-color: #CCDFEA
                }
            }
            .sign-btn{
                color: #fff;
                border-radius: 5px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                // background-image: linear-gradient(to right, #40CBD1 , #07BDD3);
                background-color: #11A1FD;
                margin-top: 25px;
                cursor: pointer;
                font-size: 16px;
                &:hover{
                    box-shadow: 0px 0px 2px #ccc;
                    background-color: #0E89D7;
                }
            }
        }
    }

}
</style>